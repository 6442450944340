<template>
  <div>
    <span
      v-if="label"
      class="cy-btn-toggle__label">
      {{ label }}
    </span>

    <v-btn-toggle
      v-bind="$attrs"
      class="cy-btn-toggle__wrapper"
      :mandatory="mandatory"
      @change="!$attrs.readonly && $emit('input', $event)">
      <CyTooltip
        v-for="({ key, icon, image, text, theme = activeTheme, value, disabled, tooltip, isLoading = false, isInlineLoader = false }, index) of items"
        :key="key"
        :disabled="!tooltip"
        top>
        <template #activator="{ on }">
          <CyButton
            :aria-label="text || key"
            :disabled="disabled || ($attrs.readonly && getValue(value, index) !== $attrs.value)"
            :theme="theme"
            :loading="isLoading"
            :loading-text="isInlineLoader ? text : ''"
            :value="getValue(value, index)"
            variant="secondary"
            :class="['cy-btn-toggle', {
              'cy-btn-toggle--inactive': getValue(value, index) !== $attrs.value,
              'cy-btn-toggle--readonly': $attrs.readonly,
            }]"
            v-on="on">
            <component
              v-if="isSvg(image)"
              :class="['cy-btn-toggle__image', ...getImageClasses({ key })]"
              :is="image"/>
            <img
              v-else-if="image"
              :class="['cy-btn-toggle__image', ...getImageClasses({ key })]"
              :alt="`${key} image`"
              :src="image">
            <v-icon
              v-if="icon"
              :class="['cy-btn-toggle__icon', ...getIconClasses({ key })]">
              {{ icon }}
            </v-icon>
            <span
              v-if="!onlyShowIcons"
              :class="['cy-btn-toggle__text', {
                'cy-btn-toggle__text--no-margin': onlyShowText,
              }]"
              v-html="$sanitizeHtml(text)"/>
          </CyButton>
        </template>
        {{ tooltip }}
      </CyTooltip>
    </v-btn-toggle>
  </div>
</template>

<script>
import { checksPass } from '@/utils/helpers'

const errorHeader = '[CyButtonToggle]'
const endMessage = '\n\nAll items should have a similar shape:\n{ key, text } just text\n{ key, icon/image } just an image or icon\n{ key, text, icon/image } both text and image or icon.\n'
export const errorMessage = {
  shouldBeOnlyText: `${errorHeader} Some items contain icons/images, but the first item contains only text. ${endMessage}`,
  shouldBeOnlyIcon: `${errorHeader} Some items contain text, but the first item contains only an image/icon. ${endMessage}`,
  shouldBeBothTextAndIcon: `${errorHeader} Not all items contain both text and icons/images, but the first item contains both. ${endMessage}`,
  unknown: `${errorHeader} Something went wrong, incorrect fields for each item were supplied. ${endMessage}`,
}
export function allItemsHaveSameFields (items) {
  const onlyShowText = !_.$hasAny(items[0], ['icon', 'image']) && _.has(items[0], 'text')
  const onlyShowIcons = _.$hasAny(items[0], ['icon', 'image']) && !_.has(items[0], 'text')
  const everyItemHas = {
    onlyText: items.every((item) => !_.$hasAny(item, ['icon', 'image']) && _.has(item, 'text')),
    onlyIcon: items.every((item) => _.$hasAny(item, ['icon', 'image']) && !_.has(item, 'text')),
    bothTextAndIcon: items.every((item) => _.$hasAny(item, ['icon', 'image']) && _.has(item, 'text')),
  }
  const allItemsHaveSameFields = (onlyShowText && everyItemHas.onlyText) ||
    (onlyShowIcons && everyItemHas.onlyIcon) ||
    everyItemHas.bothTextAndIcon

  if (!allItemsHaveSameFields) {
    if (onlyShowText && !everyItemHas.onlyText) console.error(errorMessage.shouldBeOnlyText, items)
    else if (onlyShowIcons && !everyItemHas.onlyIcon) console.error(errorMessage.shouldBeOnlyIcon, items)
    else if (!everyItemHas.bothTextAndIcon) console.error(errorMessage.shouldBeBothTextAndIcon, items)
    else console.error(errorMessage.unknown, items)
  }
  return allItemsHaveSameFields
}

export default {
  name: 'CyButtonToggle',
  props: {
    activeTheme: {
      type: String,
      default: 'secondary',
    },
    items: {
      type: Array,
      validator: (items) => !_.isEmpty(items) && items.every((item) => checksPass({
        isAnObject: _.isPlainObject(item),
        hasKeyField: _.has(item, 'key'),
        hasAnyTextOrIconFields: _.$hasAny(item, ['text', 'image', 'icon']),
      })) && allItemsHaveSameFields(items),
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    imageClass: {
      type: [String, Object, Array],
      default: null,
    },
    iconClass: {
      type: [String, Object, Array],
      default: null,
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    onlyShowText () {
      return !_.$hasAny(this.items[0], ['icon', 'image']) && _.has(this.items[0], 'text')
    },
    onlyShowIcons () {
      return _.$hasAny(this.items[0], ['icon', 'image']) && !_.has(this.items[0], 'text')
    },
  },
  watch: {
    items: {
      handler () {
        if (_.find(this.items, ['value', this.$attrs.value])?.disabled) {
          const firstEnabledValue = this.items.find(({ disabled }) => !disabled)?.value
          if (firstEnabledValue) this.$emit('input', firstEnabledValue)
        }
      },
      deep: true,
    },
  },
  methods: {
    getImageClasses ({ key }) {
      const { imageClass } = this
      const keyedImageClass = `cy-btn-toggle__image--${key}`
      if (_.isString(imageClass) && !_.isEmpty(imageClass)) return [keyedImageClass, imageClass]
      if (_.isArray(imageClass)) return [keyedImageClass, ...imageClass]
      if (_.isPlainObject(imageClass)) return [keyedImageClass, imageClass]
      return [keyedImageClass]
    },
    getIconClasses ({ key }) {
      const { iconClass } = this
      const keyedIconClass = `cy-btn-toggle__icon--${key}`
      if (_.isString(iconClass) && !_.isEmpty(iconClass)) return [keyedIconClass, iconClass]
      if (_.isArray(iconClass)) return [keyedIconClass, ...iconClass]
      if (_.isPlainObject(iconClass)) return [keyedIconClass, iconClass]
      return [keyedIconClass]
    },
    getValue (value, index) {
      return value ?? index
    },
    isSvg (image) {
      return _(image).keys().includes('render')
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-btn-toggle {
  z-index: 2;
  height: 32px !important;
  padding: 0 12px !important;

  &__wrapper {
    box-shadow: none;

    .v-btn-toggle,
    .v-btn-toggle--selected {
      box-shadow: none;
    }
  }

  &__label {
    display: block;
    padding-bottom: 7px;
    color: cy-get-color("primary");
    font-size: 12px;
  }

  &__image {
    width: 24px;
    height: 24px;
  }

  &__icon {
    margin: 0 !important;
    color: currentColor !important;
  }

  &__text {
    margin-left: 0.5em;
    color: cy-get-color("primary") !important;

    &--no-margin {
      margin: 0;
    }
  }

  &--inactive:not(:focus, :active, :hover) {
    z-index: 1;
    color: cy-get-color("grey") !important;

    .v-icon {
      color: cy-get-color("grey", "dark-2") !important;
    }
  }

  &--readonly {
    cursor: not-allowed;
  }

  ::v-deep &.cy-btn {
    margin-left: -1px !important;
    opacity: 1;

    &--active {
      z-index: 1;
    }

    &:focus,
    &:hover {
      z-index: 3;
    }

    &:focus:not(:active) {
      &::before {
        content: "";
        position: absolute;
        opacity: 0.5;
        background: none;
        box-shadow: 0 0 0 4px currentColor;
      }
    }
  }
}
</style>
